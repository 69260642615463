import {useCarousel, UseCarouselState} from 'lib/carousel/hooks/useCarousel';
import React from 'react';
import styles from './index.module.scss';

export type ChildrenProps = UseCarouselState<HTMLUListElement>;
export type Props = {
  children(props: ChildrenProps): React.ReactNode;
};

export function Container({children}: Props): React.ReactElement {
  const carouselState = useCarousel();

  return <div className={styles.container}>{children(carouselState)}</div>;
}
