import {useDevicevars} from 'lib/devicevars';
import {useStore} from 'lib/effector';
import {reverseUrl} from 'lib/router';
import {$contactUsDialog} from 'models/selfService/contactUs';
import {$ssRegistrationDialog} from 'models/ssRegistrationDialog';
import {$userAnonymous} from 'models/user';
import {useRouter} from 'next/router';
import React, {useEffect} from 'react';
import {MainPtBrV1} from './MainPtBrV1';
import {MainPtBrV2} from './MainPtBrV2';

export function MainPtBr(): React.ReactElement {
  const router = useRouter();
  const {landingConfig, catalog112024, landingBr112024, isRedirectEveryone} = useDevicevars();

  const userAnonymous = useStore($userAnonymous);
  const {isOpen: contactUsOpen} = useStore($contactUsDialog);
  const {opened: registrationOpen} = useStore($ssRegistrationDialog);

  useEffect(() => {
    if (
      !contactUsOpen &&
      !registrationOpen &&
      landingConfig?.main.catalogRedirect &&
      catalog112024 &&
      (!userAnonymous || isRedirectEveryone)
    ) {
      router.replace(reverseUrl.search());
    }
  }, [
    contactUsOpen,
    registrationOpen,
    catalog112024,
    landingConfig?.main.catalogRedirect,
    isRedirectEveryone,
    userAnonymous,
  ]);

  return landingConfig?.main?.show && landingBr112024 ? <MainPtBrV2 /> : <MainPtBrV1 />;
}
