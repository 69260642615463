import {SearchBar} from 'components/SearchBar';
import {SearchRefState} from 'components/SearchBase';
import {SearchSuggestion} from 'lib/search/suggest';
import React, {forwardRef, ReactElement} from 'react';
import {ButtonColor} from 'uikit/Button';
import {HeaderTheme, HeaderShape} from '../types';

type Props = {
  addon?: ReactElement;
  isLockedImageSearch?: boolean;
  onSearchPhraseSuggest: (phrase: string) => void;
  searchPhrase?: string;
  searchSuggestions?: SearchSuggestion[];
  searchSuggestionsPhrase?: string;
  shape?: HeaderShape;
  theme: HeaderTheme;
};

const SEARCH_BUTTON_COLOR_BY_THEME: Record<HeaderTheme, ButtonColor> = {
  dark: 'accent',
  light: 'black',
};

export const HeaderSearchBar = forwardRef<SearchRefState, Props>(
  (
    {
      addon,
      theme,
      searchPhrase,
      searchSuggestions,
      searchSuggestionsPhrase,
      shape,
      onSearchPhraseSuggest,
      isLockedImageSearch,
    },
    ref,
  ): React.ReactElement => {
    return (
      <SearchBar
        addon={addon}
        initialPhrase={searchPhrase}
        isLockedImageSearch={isLockedImageSearch}
        onSuggest={onSearchPhraseSuggest}
        ref={ref}
        searchButtonColor={SEARCH_BUTTON_COLOR_BY_THEME[theme]}
        searchSuggestions={searchSuggestions}
        searchSuggestionsPhrase={searchSuggestionsPhrase}
        shape={shape}
      />
    );
  },
);
