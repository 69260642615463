import {defineMessages} from 'react-intl';

export const importProcessMessages = defineMessages({
  button: {
    defaultMessage: 'Start now',
    description: '[button] Import process Main',
  },
  step1Description: {
    defaultMessage: 'Browse our catalog, find what you need, and check the estimated costs for your operation',
    description: '[description] Import process step Main',
  },
  step1Title: {
    defaultMessage: 'Choose your products',
    description: '[description] Import process step Main',
  },
  step2Description: {
    defaultMessage:
      'Add the products you want to import to your cart and send your final quote request. Our team will analyze your order and find the ideal supplier.',
    description: '[description] Import process step Main',
  },
  step2Title: {
    defaultMessage: 'Request your quote',
    description: '[description] Import process step Main',
  },
  step3Description: {
    defaultMessage:
      'Before placing a large order, you can request physical or virtual samples to ensure the products meet your expectations',
    description: '[description] Import process step Main',
  },
  step3Title: {
    defaultMessage: 'Request samples',
    description: '[description] Import process step Main',
  },
  step4Description: {
    defaultMessage:
      'Develop and personalize your products to position your brand as a relevant player in the market, distinguishing yourself from the competition',
    description: '[description] Import process step Main',
  },
  step4Title: {
    defaultMessage: 'Customize your product',
    description: '[description] Import process step Main',
  },
  step5Description: {
    defaultMessage:
      'Our team in China conducts quality control and inspects the batch before releasing it for shipment',
    description: '[description] Import process step Main',
  },
  step5Title: {
    defaultMessage: 'Quality assurance',
    description: '[description] Import process step Main',
  },
  step6Description: {
    defaultMessage:
      'We handle everything: transporting your goods from the factory to the port in China, and then to Brazil',
    description: '[description] Import process step Main',
  },
  step6Title: {
    defaultMessage: 'International freight',
    description: '[description] Import process step Main',
  },
  step7Description: {
    defaultMessage: 'We handle all the bureaucratic aspects, taking care of documentation and customs clearance',
    description: '[description] Import process step Main',
  },
  step7Title: {
    defaultMessage: 'Customs clearance',
    description: '[description] Import process step Main',
  },
  step8Description: {
    defaultMessage: 'We handle domestic shipping all the way to your warehouse in Brazil',
    description: '[description] Import process step Main',
  },
  step8Title: {
    defaultMessage: 'Receive at your warehouse',
    description: '[description] Import process step Main',
  },
  title: {
    defaultMessage: 'A simple step-by-step for your import process',
    description: '[title] Import process Main',
  },
});
