import cnb from 'classnames/bind';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {Container} from './Container';
import {Content} from './Content';
import {ControlButton} from './ControlButton';
import styles from './index.module.scss';
import {Shadow} from './Shadow';

const cn = cnb.bind(styles);

export type CarouseTestId = {
  leftButton: unknown;
  rightButton: unknown;
};

export type Props = TestIdProp<CarouseTestId> & {
  children: React.ReactNode;
  withShadows?: boolean;
};

export const Carousel = ({children, testId, withShadows = false}: Props): React.ReactElement => {
  return (
    <Container>
      {({contentRef, isLeftBoundaryReached, isRightBoundaryReached, onLeftClick, onRightClick}) => {
        const showControlButtons = isRightBoundaryReached || isLeftBoundaryReached;

        return (
          <>
            <Shadow direction='right' visible={withShadows && isLeftBoundaryReached} />
            <Content ref={contentRef}>{children}</Content>
            <Shadow direction='left' visible={withShadows && isRightBoundaryReached} />
            {showControlButtons && (
              <div className={styles.arrows}>
                <ControlButton
                  disabled={!isLeftBoundaryReached}
                  onClick={onLeftClick}
                  position='left'
                  testId={testId?.leftButton}
                >
                  <div className={cn('icon', 'left', {disabled: !isLeftBoundaryReached})} />
                </ControlButton>
                <ControlButton
                  disabled={!isRightBoundaryReached}
                  onClick={onRightClick}
                  position='right'
                  testId={testId?.rightButton}
                >
                  <div className={cn('icon', 'right', {disabled: !isRightBoundaryReached})} />
                </ControlButton>
              </div>
            )}
          </>
        );
      }}
    </Container>
  );
};
