import {HeaderActions as HeaderActionsBase} from 'components/Header/HeaderActions';
import {HeaderTheme, HeaderAction, HeaderShape} from 'components/Header/types';
import {useStore, useEvent} from 'lib/effector';
import {signOutFx} from 'models/auth';
import {$cartItemsCount} from 'models/cart/itemsCount';
import {openSsAuthDialog} from 'models/ssRegistrationDialog';
import {$user} from 'models/user';
import React, {useCallback} from 'react';

type Props = {
  actions: HeaderAction[];
  shape?: HeaderShape;
  theme?: HeaderTheme;
};

export function HeaderActions({theme = 'light', shape, actions}: Props): React.ReactElement {
  const user = useStore($user);
  const cartItemsCount = useStore($cartItemsCount);
  const signInEvent = useEvent(openSsAuthDialog);
  const signOutEvent = useEvent(signOutFx);
  const handleSignIn = useCallback(() => {
    signInEvent({pageUrl: window.location.href, source: 'header'});
  }, [signInEvent]);

  return (
    <HeaderActionsBase
      actions={actions}
      cartItemsCount={cartItemsCount.data}
      onSignIn={handleSignIn}
      onSignOut={signOutEvent}
      shape={shape}
      theme={theme}
      user={user}
    />
  );
}
