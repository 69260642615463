import {createPopper, Placement, Options} from '@popperjs/core';
import cnb from 'classnames/bind';
import {Portal} from 'components/Portal';
import {useIsomorphicLayoutEffect} from 'lib/hooks';
import {TestIdProp} from 'lib/testing/types';
import React, {useEffect, useRef} from 'react';
import styles from './index.module.scss';

const cn = cnb.bind(styles);

export type TooltipPlacement = Placement;

export type TooltipTargetRef = React.RefObject<Element>;

export type TooltipIntention = 'error' | 'default';

export type TooltipTestId = {
  arrow: unknown;
  content: unknown;
};

export type TooltipProps = TestIdProp<TooltipTestId> & {
  children?: React.ReactNode;
  id?: string;
  intent?: TooltipIntention;
  isInsideDialog?: boolean;
  noArrow?: boolean;
  noPadding?: boolean;
  onClose?: () => void;
  placement?: TooltipPlacement;
  targetRef?: TooltipTargetRef;
};

const MARGIN = 16;

export function Tooltip({
  children,
  id,
  noArrow,
  noPadding,
  onClose,
  intent = 'default',
  placement = 'bottom',
  targetRef,
  testId,
  isInsideDialog = false,
}: TooltipProps): React.ReactElement {
  const insideAnchorRef = useRef(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef(null);

  useEffect(() => {
    if (!onClose) {
      return undefined;
    }

    window.addEventListener('mousedown', onClose);

    return () => {
      window.removeEventListener('mousedown', onClose);
    };
  }, [onClose]);

  useIsomorphicLayoutEffect(() => {
    const referenceElement = targetRef ? targetRef.current : insideAnchorRef.current;

    if (!referenceElement || !tooltipRef.current) {
      return undefined;
    }

    const modifiers: Options['modifiers'] = [
      {
        name: 'preventOverflow',
        options: {
          padding: MARGIN,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ];

    if (arrowRef.current) {
      modifiers.push({
        name: 'arrow',
        options: {
          element: arrowRef.current,
          padding: 10,
        },
      });
    }

    const popper = createPopper(referenceElement, tooltipRef.current, {
      modifiers,
      placement,
    });

    return () => {
      popper.destroy();
    };
  }, [targetRef?.current]);

  const content = (
    <Portal>
      <div
        className={cn(
          styles.tooltip,
          noPadding && styles.noPadding,
          `intent-${intent}`,
          isInsideDialog && styles.insideDialog,
        )}
        data-test-id={testId}
        id={id}
        ref={tooltipRef}
        role='tooltip'
      >
        {!noArrow && <div className={styles.arrow} data-test-id={testId?.arrow} ref={arrowRef} />}
        <div
          className={styles.content}
          data-test-id={testId?.content}
          style={{maxWidth: `calc(100vw - ${MARGIN * 2}px)`}}
        >
          {children}
        </div>
      </div>
    </Portal>
  );

  if (targetRef) {
    return content;
  }

  return <div ref={insideAnchorRef}>{content}</div>;
}
