import throttle from 'lodash/throttle';
import {useRouter} from 'next/router';
import React, {useCallback, useEffect, useState} from 'react';

const OFFSET = 0;
const RESIZE_THROTTLE_TIMEOUT = 100;

export function useBoundaries(containerRef: React.RefObject<HTMLElement>): {
  isLeftBoundaryReached: boolean;
  isRightBoundaryReached: boolean;
} {
  const router = useRouter();
  const [isLeftBoundaryReached, setLeftBoundaryReached] = useState(true);
  const [isRightBoundaryReached, setRightBoundaryReached] = useState(true);

  const updateBoundaries = useCallback(
    throttle(() => {
      const scrollLeft = Math.ceil(containerRef.current?.scrollLeft || 0);
      const clientWidth = Math.ceil(containerRef.current?.clientWidth || 0);
      const scrollWidth = Math.ceil(containerRef.current?.scrollWidth || 0);

      setLeftBoundaryReached(scrollLeft > OFFSET);
      setRightBoundaryReached(scrollLeft + clientWidth < scrollWidth - OFFSET);
    }, RESIZE_THROTTLE_TIMEOUT),
    [],
  );

  useEffect(() => {
    if (containerRef.current) {
      window.addEventListener('resize', updateBoundaries);
      containerRef.current.addEventListener('scroll', updateBoundaries, {passive: true});
      router.events.on('routeChangeComplete', updateBoundaries);
    }

    updateBoundaries();

    return () => {
      if (containerRef.current) {
        window.removeEventListener('resize', updateBoundaries);
        containerRef.current.removeEventListener('scroll', updateBoundaries);
        router.events.off('routeChangeComplete', updateBoundaries);
      }
    };
  }, []);

  return {
    isLeftBoundaryReached,
    isRightBoundaryReached,
  };
}
