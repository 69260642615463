import {Card} from 'components/Main/Card';
import {SearchBar} from 'components/SearchBar';
import {SearchRefState} from 'components/SearchBase';
import React, {forwardRef} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {SearchProps} from '../../types';
import styles from './index.module.scss';
import {formatShorthandNumber} from './utils';

const messages = defineMessages({
  highlight: {
    defaultMessage: 'wholesale purchasing and importing from China',
    description: 'Self service main page. Card with search bar: title (second part)',
  },
  subTitle: {
    defaultMessage:
      'More than {countOfProducts} products from {countOfSuppliers} suppliers in {countOfCategories} categories',
    description: 'Self service main page. Subtitle',
  },
  title: {
    defaultMessage: 'End-to-end platform for',
    description: 'Self service main page. Card with search bar: title (first part)',
  },
});

export type Payload = {
  countOfCategories: number;
  countOfProducts: number;
  countOfSuppliers: number;
};

export type Props = SearchProps & Payload;

export const CardWithSearch = forwardRef<SearchRefState, Props>(
  (
    {
      addon,
      onSuggest,
      countOfProducts,
      countOfSuppliers,
      countOfCategories,
      searchSuggestions,
      searchSuggestionsPhrase,
    },
    ref,
  ): React.ReactElement => {
    const intl = useIntl();

    return (
      <Card color='black' padding='l' rounded='m'>
        <div className={styles.header}>
          <span>{intl.formatMessage(messages.title)}</span>
          <span className={styles.highlight}>{intl.formatMessage(messages.highlight)}</span>
        </div>
        <div className={styles.subTitle}>
          {intl.formatMessage(messages.subTitle, {
            countOfCategories: formatShorthandNumber(countOfCategories, intl),
            countOfProducts: formatShorthandNumber(countOfProducts, intl),
            countOfSuppliers: formatShorthandNumber(countOfSuppliers, intl),
          })}
        </div>
        <div className={styles.search}>
          <SearchBar
            addon={addon}
            onSuggest={onSuggest}
            ref={ref}
            searchButtonColor='accent'
            searchSuggestions={searchSuggestions}
            searchSuggestionsPhrase={searchSuggestionsPhrase}
          />
        </div>
      </Card>
    );
  },
);
