import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {useClickOutside} from '@joomcode/deprecated-utils/react/useClickOutside';
import cn from 'classnames';
import {VisuallyHidden} from 'components/VisuallyHidden';
import {useAnalytics} from 'lib/analytics';
import {useDevicevars} from 'lib/devicevars';
import {useEffectTask, useEvent, useStore} from 'lib/effector';
import {registerUserFx} from 'models/registrationCallback/registrationEvent';
import {getImageEmbeddingFx} from 'models/search';
import {$user} from 'models/user';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Tooltip} from 'uikit/Tooltip';
import styles from './index.module.scss';
import {TooltipDefaultContent} from './TooltipDefaultContent';
import {TooltipFailedUploadContent} from './TooltipFailedUploadContent';
import {TooltipSizeTooLargeContent} from './TooltipSizeTooLargeContent';

const DEFAULT_ACCEPT_VALUE = 'image/*';
const DEFAULT_MAX_FILE_SIZE = 3 * 10 ** 6; // 3Mb

enum ErrorType {
  FAILED_UPLOAD = 'failedUpload',
  SIZE_TOO_LARGE = 'sizeTooLarge',
}

function getContentByErrorType(errorType: ErrorType): React.ReactElement | null {
  switch (errorType) {
    case ErrorType.FAILED_UPLOAD:
      return <TooltipFailedUploadContent />;
    case ErrorType.SIZE_TOO_LARGE:
      return <TooltipSizeTooLargeContent />;
    default:
      return null;
  }
}

type Props = {
  accept?: string;
  isSmall?: boolean;
  maxSize?: number;
  onChange(payload: {id: string}): void;
};

export function ImageSearchAddon({
  onChange,
  accept = DEFAULT_ACCEPT_VALUE,
  maxSize = DEFAULT_MAX_FILE_SIZE,
  isSmall,
}: Props): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipState = useBooleanState(false);
  const [error, setError] = useState<ErrorType | undefined>(undefined);
  const registerUser = useEvent(registerUserFx);
  const {anonymous} = useStore($user);

  const {unauthUsersLockedFeatures} = useDevicevars();
  const isLockedImageSearch = anonymous && Boolean(unauthUsersLockedFeatures);
  const analytics = useAnalytics();

  const buttonClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef.current]);

  const handleClick = useCallback(() => {
    if (!isLockedImageSearch) {
      buttonClick();
    }

    if (isLockedImageSearch) {
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          source: 'searchImage',
        },
        type: 'signUpOpen',
      });

      registerUser().finally(buttonClick);
    }
  }, [isLockedImageSearch, buttonClick, registerUser]);

  const handleMouseEnter = useCallback(() => {
    tooltipState.setTrue();
    setError(undefined);
  }, [setError, tooltipState.setTrue]);

  const getImageEmbeddingTask = useEffectTask(getImageEmbeddingFx, []);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.files) {
        const files = Array.from(event.currentTarget.files);
        const filtered = files.filter((file) => file.size < maxSize);
        if (filtered.length > 0) {
          const file = filtered[0];
          const payload = {
            filename: file.name,
            image: file,
          };

          getImageEmbeddingTask.perform(payload);
        } else if (files.length > 0) {
          setError(ErrorType.SIZE_TOO_LARGE);
        }
      }

      // eslint-disable-next-line no-param-reassign
      event.currentTarget.value = '';
    },
    [setError, onChange, maxSize],
  );

  useClickOutside(() => setError(undefined), []);
  useEffect(() => {
    if (getImageEmbeddingTask.state === DataState.LOADED) {
      if (getImageEmbeddingTask.result) {
        onChange({id: getImageEmbeddingTask.result.id});
      }
    } else if (getImageEmbeddingTask.state === DataState.FAILED) {
      setError(ErrorType.FAILED_UPLOAD);
    }
  }, [getImageEmbeddingTask.state]);

  return (
    <div className={styles.container}>
      <button
        className={cn(
          styles.button,
          getImageEmbeddingTask.pending && styles.loading,
          isSmall ? styles.small : undefined,
        )}
        disabled={getImageEmbeddingTask.pending}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={tooltipState.setFalse}
        ref={buttonRef}
        type='button'
      >
        <span className={styles.icon}></span>
      </button>
      {(tooltipState.value || error) && (
        <Tooltip intent={error ? 'error' : 'default'} noPadding placement='bottom' targetRef={buttonRef}>
          {error ? getContentByErrorType(error) : <TooltipDefaultContent />}
        </Tooltip>
      )}
      <VisuallyHidden>
        <input accept={accept} onChange={handleChange} ref={inputRef} type='file' />
      </VisuallyHidden>
    </div>
  );
}
