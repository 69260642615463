import cnb from 'classnames/bind';
import React from 'react';
import styles from './index.module.scss';

const cn = cnb.bind(styles);

export type Direction = 'left' | 'right';
export type Props = {
  direction: Direction;
  visible: boolean;
};

export function Shadow({visible, direction}: Props): React.ReactElement {
  return <div className={cn(styles.shadow, direction, {visible})} />;
}
