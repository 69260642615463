import {HeaderSearchBar as HeaderSearchBarBase} from 'components/Header/HeaderSearchBar';
import {HeaderShape, HeaderTheme} from 'components/Header/types';
import {SearchRefState} from 'components/SearchBase';
import {ImageSearchAddon} from 'containers/ImageSearchAddon';
import {useEvent, useStore} from 'lib/effector';
import {reverseUrl} from 'lib/router';
import {$searchPhrase} from 'models/search';
import {$searchSuggestions, searchPhraseSuggestFx} from 'models/search/suggest';
import {useRouter} from 'next/router';
import React, {useCallback, useRef} from 'react';

type Props = {
  shape?: HeaderShape;
  theme: HeaderTheme;
};

export function HeaderSearchBar({theme, shape}: Props): React.ReactElement {
  const {searchSuggestions, searchSuggestionsPhrase} = useStore($searchSuggestions);
  const searchPhrase = useStore($searchPhrase);

  const handleSuggestSearchQuery = useEvent(searchPhraseSuggestFx);

  const searchRef = useRef<SearchRefState>(null);
  const router = useRouter();

  const handleSearchByImage = useCallback(({id}: {id: string}) => {
    searchRef.current?.clear();
    router.push(reverseUrl.search({imageId: id}));
  }, []);

  return (
    <HeaderSearchBarBase
      addon={<ImageSearchAddon isSmall={shape === 'classic'} onChange={handleSearchByImage} />}
      onSearchPhraseSuggest={handleSuggestSearchQuery}
      ref={searchRef}
      searchPhrase={searchPhrase}
      searchSuggestions={searchSuggestions}
      searchSuggestionsPhrase={searchSuggestionsPhrase}
      shape={shape}
      theme={theme}
    />
  );
}
