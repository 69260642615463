import {SearchRefState} from 'components/SearchBase';
import {ImageSearchAddon} from 'containers/ImageSearchAddon';
import {Category} from 'lib/category/types';
import {useDevicevars} from 'lib/devicevars';
import {reverseUrl} from 'lib/router';
import {useRouter} from 'next/router';
import {forwardRef, ForwardedRef, useMemo, useRef, useCallback} from 'react';
import {CardContactUs} from '../../components/CardContactUs';
import {CardWithSearch, Payload as CardWithSearchPayload} from '../../components/CardWithSearch';
import {Categories} from '../../components/Categories';
import {SearchProps} from '../../types';
import styles from './index.module.scss';

type Props = SearchProps & {
  categories: Category[];
  onContactUs?(): void;
};

export const BlockCatalog = forwardRef(
  (
    {categories, onSuggest, onContactUs, searchSuggestions, searchSuggestionsPhrase}: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ): React.ReactElement => {
    const {landingBrPayload} = useDevicevars();
    const searchRef = useRef<SearchRefState>(null);
    const router = useRouter();
    const cardWithSearchPayload = useMemo<CardWithSearchPayload>(() => {
      if (landingBrPayload) {
        return landingBrPayload;
      }

      return {
        countOfCategories: 0,
        countOfProducts: 0,
        countOfSuppliers: 0,
      };
    }, [landingBrPayload]);

    const handleSearchByImage = useCallback(({id}: {id: string}) => {
      searchRef.current?.clear();
      router.push(reverseUrl.search({imageId: id}));
    }, []);

    return (
      <div className={styles.container} ref={ref}>
        <div className={styles.header}>
          <CardWithSearch
            addon={<ImageSearchAddon onChange={handleSearchByImage} />}
            onSuggest={onSuggest}
            ref={searchRef}
            searchSuggestions={searchSuggestions}
            searchSuggestionsPhrase={searchSuggestionsPhrase}
            {...cardWithSearchPayload}
          />
          <CardContactUs onContact={onContactUs} />
        </div>
        <div className={styles.categories}>
          <Categories categories={categories} />
        </div>
      </div>
    );
  },
);
