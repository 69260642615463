import { useEffect as s } from "react";
function v(u, r, {
  disabled: m,
  /**
   * `useCapture` is set to true by default so that a `stopPropagation` in the
   * children will not prevent all outside click handlers from firing
   */
  useCapture: e = !0
} = {}) {
  s(() => {
    if (m || r.some((n) => !n.current))
      return;
    function o(n) {
      document.removeEventListener("mouseup", o, e), r.every((t) => t.current && !t.current.contains(n.target)) && u();
    }
    function c(n) {
      r.every((t) => t.current && !t.current.contains(n.target)) && document.addEventListener("mouseup", o, e);
    }
    return document.addEventListener("mousedown", c, e), () => {
      document.removeEventListener("mousedown", c, e), document.removeEventListener("mouseup", o, e);
    };
  }, [m, u, r]);
}
export {
  v as useClickOutside
};
