import cnb from 'classnames/bind';
import ChevronLeftIcon from 'icons/chevron-left.svg';
import ChevronRightIcon from 'icons/chevron-right.svg';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {Container} from '../Container';
import {Content} from '../Content';
import {ControlButton} from '../ControlButton';
import styles from './index.module.scss';

const cn = cnb.bind(styles);

export type CompactTestId = {
  leftButton: unknown;
  rightButton: unknown;
};

export type Props = TestIdProp<CompactTestId> & {
  alignContent?: 'center' | 'left';
  children: React.ReactNode;
};

export function Compact({alignContent = 'center', children, testId}: Props): React.ReactElement {
  return (
    <Container>
      {({contentRef, isLeftBoundaryReached, isRightBoundaryReached, onLeftClick, onRightClick}) => {
        const withControls = isRightBoundaryReached || isLeftBoundaryReached;

        return (
          <>
            <div className={cn('content', alignContent)}>
              <Content ref={contentRef}>{children}</Content>
            </div>
            {withControls && (
              <>
                <ControlButton
                  disabled={!isLeftBoundaryReached}
                  onClick={onLeftClick}
                  position='left'
                  testId={testId?.leftButton}
                >
                  <ChevronLeftIcon className={cn('icon', {disabled: !isLeftBoundaryReached})} />
                </ControlButton>
                <ControlButton
                  disabled={!isRightBoundaryReached}
                  onClick={onRightClick}
                  position='right'
                  testId={testId?.rightButton}
                >
                  <ChevronRightIcon className={cn('icon', {disabled: !isRightBoundaryReached})} />
                </ControlButton>
              </>
            )}
          </>
        );
      }}
    </Container>
  );
}
