import {useRef, useCallback} from 'react';
import {useBoundaries} from './useBoundaries';

export type UseCarouselState<E extends HTMLElement> = {
  contentRef: React.RefObject<E>;
  isLeftBoundaryReached: boolean;
  isRightBoundaryReached: boolean;
  onLeftClick(): void;
  onRightClick(): void;
};

export function useCarousel<E extends HTMLElement = HTMLUListElement>(): UseCarouselState<E> {
  const contentRef = useRef<E>(null);
  const {isRightBoundaryReached, isLeftBoundaryReached} = useBoundaries(contentRef);

  const onLeftClick = useCallback(() => {
    if (contentRef.current) {
      const {scrollLeft} = contentRef.current;

      for (let i = contentRef.current.children.length; i--; ) {
        const item = contentRef.current.children[i] as HTMLElement;
        const itemOffset = item.offsetLeft;
        if (itemOffset < scrollLeft) {
          contentRef.current.scrollLeft = item.offsetLeft;
          break;
        }
      }
    }
  }, []);

  const onRightClick = useCallback(() => {
    if (contentRef.current) {
      const {scrollLeft, offsetWidth} = contentRef.current;
      const rightOffset = scrollLeft + offsetWidth;

      for (let i = 0; i < contentRef.current.children.length; i++) {
        const item = contentRef.current.children[i] as HTMLElement;
        const itemOffset = item.offsetLeft + item.offsetWidth;
        if (itemOffset > rightOffset) {
          contentRef.current.scrollLeft = item.offsetLeft - offsetWidth + item.offsetWidth;
          break;
        }
      }
    }
  }, []);

  return {
    contentRef,
    isLeftBoundaryReached,
    isRightBoundaryReached,
    onLeftClick,
    onRightClick,
  };
}
