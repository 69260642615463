import React, {forwardRef} from 'react';
import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
};

export const Content = forwardRef<HTMLUListElement, Props>(({children}, ref): React.ReactElement => {
  const slides = React.Children.toArray(children);

  return (
    <ul className={styles.slides} ref={ref}>
      {slides.map((slide, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={styles.slide} key={idx}>
          {slide}
        </li>
      ))}
    </ul>
  );
});
